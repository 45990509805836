
body {
    font-family: 'Roboto', 'Droid Sans', 'Helvetica', sans-serif;
  }
form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input {
    width: 90%;
    padding: 8px;
    margin-bottom: 9px;
    box-sizing: border-box;
    font-size: 13px;
    padding: 12px;
    background: #ededed70;
    border:none;
  }
  
  button {
    width: 100%;
    padding: 12px;
    background-color: #1877f2;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    width: 90%;
    font-size: 17px;
    font-weight: bold;

  }
  .marginbottom{
    margin-bottom:15px;
  }

.logo {
    width: 112px;
}

.line {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: #000;
  }
  
  .line::before,
  .line::after {
    content: '';
    position: absolute;
    top: 0;
    width: 10px;
    height: 1px;
    background-color: #000;
  }
  
  .line::before {
    left: -10px;
  }
  
  .line::after {
    right: -10px;
  }
  a{
    text-decoration: none;
    text-underline-offset: 52px;
  }
.element-with-facebook-border {
    border: 1px solid transparent;
    box-shadow: -2px 0 5px -1px #bdbdbd, 2px 0 5px -1px #bdbdbd, 0 0 7px 1px #bdbdbd;

  }

  
  @media (max-width: 768px) {
    .logo {
        max-width: 50%;
        height: auto;
        width: auto;
      }
      form {
        max-width: 100%;
      }

    }